<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , indexVal) in values" :key="indexVal">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card>
      <b-row>
        <!-- sorting Status -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Status"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              style="z-index: 9999999;"
              v-model="status"
              class="border mr-0"
              size="sm"
              :text="statusName"
              variant="flat-primary"
            >
              <div style="overflow-y: scroll; max-height: 350px;">
              <b-dropdown-item @click="changeStatus('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatus(item.name, item.slug)"
                v-for="(item, index) in orderStatus" :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              </div>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- Classification -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sales Channel"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="classification"
              class="border mr-0"
              size="sm"
              :text="classificationName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
                <b-dropdown-item @click="changeClassification('All', 'all')">
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeClassification(item.name, item.slug)"
                  v-for="(item, index) in orderClassification"
                  :key="index"
                >
                  {{ item.name }}
                </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class=""
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5;"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(desc)="data">
              <b-card-text > 
                <div v-html="data.value"></div>
              </b-card-text>
            </template>
            
            <template #cell(orderstatus)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(is_portal)="data">
              <b-badge variant="info" v-if="data.value">
                Portal
              </b-badge>
              <b-badge variant="info" v-else>
                In Store
              </b-badge>
            </template>
            <template #cell(actions)="items">
              <div>
                <!-- Show Order -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/orders/show/' + items.item.id }"
                >
                  <feather-icon
                    style="width: 18px; height: 18px;"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- update Order -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                  v-if="authPermissions.includes('update')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  type="submit"
                  :to="{ path: '/orders/Update/' + items.item.id }"
                >
                  <feather-icon
                    icon="EditIcon"
                    style="width: 18px; height: 18px;"
                  />
                </b-button>
                <!-- update Order Status -->
                <b-button
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  type="submit"
                  :to="{ path: '/orders/Update/Status/' + items.item.id }"
                >
                  change Status
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'orders-table',
  props: ['link'],
  data() {
    return {
      authPermissions: null,
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'created_at',
      status: 'all',
      statusName: 'All',
      orderStatus: [],
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      classificationName: 'All',
      classification: 'all',
      orderClassification: [
        {
          name : 'Portal',
          slug : 'portal'
        },
        {
          name: 'In Store',
          slug: 'in_store',
        }
      ],
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'is_portal',
          label: 'Sales Channel',
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'orderstatus',
          label: 'Order Status',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'discount',
          label: 'Discount',
        },
        {
          key: 'shipping_price',
          label: 'Shipping Price',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      exportItems: [],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request Orders index
    this.fetchData()

    axios
      .get('order-status')
      .then((result) => {
        this.orderStatus = result.data.data
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    status: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
    classification: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
  },
  methods: {
    // fetch data of Orders
    fetchData() {
      var url = null
      if (this.filter == '') {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.status +
          '/' +
          this.classification +
          '?page=' +
          this.currentPage
      } else {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.status +
          '/' +
          this.classification +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {

          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items = result.data.data.data
          this.exportItems = this.items
        
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    changeClassification(name, slug) {
      this.classification = slug
      this.classificationName = name
    },

    // delete function of (delete button) Order
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('admin/orders/' + id)
              .then((result) => {
                this.fetchData()
              })
              .catch((err) => {
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    changeStatus(name, slug) {
      this.status = slug
      this.statusName = name
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].username,
          this.items[index].code,
          this.items[index].orderstatus,
          this.items[index].totla,
          this.items[index].discount,
          this.items[index].shipping_price,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [
          [
            'ID',
            'User Name',
            'Code',
            'Order Status',
            'Totla',
            'Discount',
            'Shipping Price',
            'CREATED_AT',
            'UPDATED_AT',
          ],
        ],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
  },
}
</script>
