<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Courier Information -->
    <b-row class="row-eq-height">
      <b-col md="6" class="mb-2">
        <b-card title="Courier Data" class="text-center h-100 cardH">
          <h6 class="text-muted">Status: {{ courierData.active }}</h6>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-card class="h-100">
          <div v-if="delUser" class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/Courier/Update/' + courierData.id }"
              variant="primary mr-1 mt-1"
            >
              Update
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              :to="{ path: '/Courier/Add/Area/' + courierData.id }"
              variant="outline-warning"
              class="mr-1 mt-1"
            >
              Update Areas
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteCourier(courierData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Delete
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              @click="restoreCourier(courierData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>
          <hr />
          <div
            v-if="delUser"
            class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            "
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ courierData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ courierData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            "
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ courierData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ courierData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card title="Areas">
          <b-table responsive="sm" :items="items" />
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Description">
          <b-tabs align="center" class="text-center">
            <b-tab
              v-for="(translation, index) in itemTrans"
              :title="translation.locale == 'en' ? 'English' : 'Arabic'"
              :key="index"
            >
              <b-card-text v-html="translation.description"></b-card-text>
              <b-button
                v-if="authPermissions.includes('update')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="warning"
                class="btn-cart"
                :to="{
                  name: 'Courier-Update-Description',
                  params: { id: courierData.id, language: translation.locale },
                }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Update {{ translation.locale }} translation text</span>
              </b-button>
              <b-button
                v-if="
                  authPermissions.includes('delete') &&
                  translation.locale != 'en' &&
                  translation.locale != 'ar'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="btn-cart ml-1"
                @click="deleteData(courierData.id, translation.locale)"
              >
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span>Delete {{ translation.locale }} translation text</span>
              </b-button>
            </b-tab>
          </b-tabs>
          <!-- add new translation -->
          <b-button
            v-if="authPermissions.includes('create')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            :to="{
              name: 'Courier-Add-Description',
              params: { id: courierData.id },
            }"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add new translation to text</span>
          </b-button>
        </b-card>
        <!-- <b-card title="Descriptions">
                        
                        <b-table striped hover :items="items2" :fields="fields">
                            <template #cell(actions)="data">
                                        <b-button style='padding:7px; margin:6px;' v-if="authPermissions.includes('update')" :to="{name: 'Courier-Update-Description', params:{id: courierData.id,language: data.item.locale}}" variant="warning">Update</b-button>
                                        <b-button style='padding:7px; margin:6px;' v-if="authPermissions.includes('delete')" @click="deleteData(courierData.id, data.item.locale)" variant="danger">Delete</b-button>
</template>
<template #cell(value)="data">
    <b-card-text v-html="data.item.value">
    </b-card-text>
</template>
                    </b-table>
                    <b-button style='padding:7px; margin:6px;' block class="float-right" v-if="authPermissions.includes('update')" :to="{name: 'Courier-Add-Description', params:{id: courierData.id}}" variant="success">Add New Translation To Text</b-button>
                </b-card> -->
      </b-col>
    </b-row>
    <b-card>
      <b-tabs>
        <b-tab title="Orders">
          <orders-table
            :link="'couriers/' + id + '/orders-pagi/'"
          ></orders-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>

<script>
import OrdersTable from '@/Components/OrdersTable'

export default {
  components: {
    OrdersTable,
  },
  data() {
    return {
      authPermissions: [],
      id: 0,
      courierData: {},
      errors_back: [],
      showDismissibleAlert: false,
      items: [],
      items2: [],
      fields: ['id', 'locale', 'key', 'value', 'actions'],
      delUser: true,
      itemTrans: [],
    }
  },
  methods: {
    fetchData() {
      axios
        .get('couriers/' + this.id)
        .then((result) => {
          this.courierData = result.data.data
          if (this.courierData.deleted_at != null) {
            this.delUser = false
          } else {
            this.delUser = true
          }
          for (let area in this.courierData.areas) {
            const data = this.courierData.areas[area]
            this.items.push({
              name: data.name,
              price: data.price,
            })
          }
          if (this.courierData.translations.length > 0) {
            this.itemTrans = this.courierData.translations
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    deleteCourier(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete courier.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('couriers/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteData(id, locale) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete courier description language.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          axios
            .delete('couriers/' + id + '/' + locale + '/destroy')
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.fetchData()
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        })
    },
    restoreCourier(id) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete courier description language.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          axios
            .get('couriers/restore/' + id)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.fetchData()
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request brand data
    this.id = this.$route.params.id
    this.fetchData()
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

<style scoped>
.cardH {
  margin-bottom: 0px;
}
</style>
